<template>
  <ModalSplitContent>
    <template #left>
      <Image :src="image" :width="650" :height="421" loading="lazy" class="object-contain" />
    </template>
    <template #right>
      <div v-if="props.showView === 0">
        <Image
          :src="imageSmall"
          :width="358"
          :height="160"
          loading="lazy"
          class="mb-6px md:hidden"
        />
        <div class="text-body-27-medium-px">
          {{ $t('voucherActivation.giveFeedbackSuccess.lowRating.thankYou') }}
        </div>
        <div
          class="mt-3px whitespace-pre-line text-body-18-regular-mobile-px text-neutral-700 md:mt-4px"
        >
          {{ $t('voucherActivation.giveFeedbackSuccess.lowRating.letter') }}
        </div>
        <div class="mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mt-4px">
          {{ $t('voucherActivation.giveFeedbackSuccess.lowRating.bestRegards') }}
        </div>
        <div class="mt-6px text-body-16-regular-px font-bold text-neutral-700 md:hidden">
          Simon Grylka
        </div>
        <Image :src="imageSignature" :width="131" :height="49" loading="lazy" class="md:mt-8" />
        <div class="mt-3px hidden text-body-16-regular-px font-medium text-neutral-700 md:block">
          Simon<br />CEO, Cryptonow
        </div>
      </div>
      <div v-else>
        <div
          class="mb-6px hidden h-14px w-14px items-center justify-center rounded-full bg-neutral-50 md:flex"
        >
          <Icon name="heart" class="h-7px w-7px text-green" />
        </div>
        <Image
          :src="imageSmall"
          :width="358"
          :height="160"
          loading="lazy"
          class="mb-6px md:hidden"
        />
        <div class="text-body-27-medium-px">
          {{ $t('voucherActivation.giveFeedbackSuccess.title') }}
        </div>
        <div class="mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mt-4px">
          {{ $t('voucherActivation.giveFeedbackSuccess.description') }}
        </div>
        <div class="mt-6px flex gap-4px">
          <ReviewUsBox to="https://g.page/r/Ca5v4ShA-LA4EB0/review">
            <Image :src="googleImage" :height="24" :width="71" />
          </ReviewUsBox>
          <ReviewUsBox to="https://ch.trustpilot.com/evaluate/cryptonow.ch">
            <Image :src="trustpilotImage" :height="24" :width="87" />
          </ReviewUsBox>
        </div>
      </div>
      <ModalActions
        v-if="props.showView === 0"
        :primary-button="{
          label: $t('app.action.close'),
          variant: 'outline',
          onClick: handleClose
        }"
        :secondary-button="{
          label: $t('app.action.contactUs'),
          to: contactUsLink
            ? localePathByType(contactUsLink.__typename as string, contactUsLink.slug as string)
            : undefined,
          onClick: () => emit('on-close')
        }"
      />
      <ModalActions
        v-else
        :primary-button="{
          label: $t('app.action.close'),
          variant: 'outline',
          onClick: handleClose
        }"
      />
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
import image from '~/assets/images/content/feedback.png'
import imageSmall from '~/assets/images/content/feedback-small.png'
import googleImage from '~/assets/images/content/google.svg'
import trustpilotImage from '~/assets/images/content/trustpilot.svg'
import imageSignature from '~/assets/images/content/signature-simon.png'
import { Maybe, PageRecord } from '~/types/generated'

interface Props {
  showView: number
  contactUsLink?: Maybe<Pick<PageRecord, '__typename' | 'slug'>>
}

const props = defineProps<Props>()
const emit = defineEmits(['on-close'])

const handleClose = () => {
  emit('on-close')
}
const { localePathByType } = useLocalePathByType()
</script>
