<template>
  <ModalSplitContent>
    <template #left>
      <Image :src="image" :width="476" :height="616" loading="lazy" />
    </template>
    <template #right>
      <div>
        <div class="text-body-27-medium-px text-orange">
          {{ $t('voucherActivation.limitReached.title') }}
        </div>
        <div class="mb-6px mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mt-4px">
          {{ $t('voucherActivation.limitReached.description') }}
        </div>
        <div class="mb-3px text-body-16-medium-px">
          {{ $t('voucherActivation.limitReached.exchangeLimits') }}
        </div>
        <div class="flex flex-col gap-3px rounded-xl bg-neutral-50 p-4px">
          <div v-for="(item, i) in limits" :key="i" class="flex gap-6px">
            <Icon name="check-general" class="mb-auto h-6px w-6px" />
            <div class="text-body-16-regular-px">
              <div>{{ item.amount }}</div>
              <div class="text-neutral-700">{{ item.label }}</div>
            </div>
          </div>
        </div>
        <i18n-t
          keypath="voucherActivation.limitReached.regulations"
          tag="p"
          class="mt-6px text-body-16-regular-px text-neutral-700"
          scope="global"
        >
          <template #helpArticle>
            <NuxtLink
              :to="
                helpArticleLink
                  ? localePathByType(
                      helpArticleLink.__typename as string,
                      helpArticleLink.slug as string,
                      helpArticleLink.category?.slug as string,
                      helpArticleLink.topic?.slug as string
                    )
                  : undefined
              "
              class="text-body-16-medium-px text-black"
              target="_blank"
            >
              {{ $t('voucherActivation.limitReached.helpArticle') }}
            </NuxtLink>
          </template>
        </i18n-t>
      </div>
      <ModalActions
        :primary-button="{
          label: $t('voucherActivation.limitReached.close'),
          onClick: () => $emit('on-close')
        }"
        :secondary-button="{
          label: $t('voucherActivation.goBack'),
          onClick: () => $emit('on-go-back')
        }"
      />
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
import image from '~/assets/images/content/activation-limit-reached.png'
import { Maybe, HelpArticleRecord, HelpCategoryRecord, HelpTopicRecord } from '~/types/generated'

interface Props {
  helpArticleLink?: Maybe<
    Pick<HelpArticleRecord, '__typename' | 'slug'> & {
      category?: Maybe<Pick<HelpCategoryRecord, 'slug'>>
      topic?: Maybe<Pick<HelpTopicRecord, 'slug'>>
    }
  >
}

defineProps<Props>()

defineEmits(['on-close', 'on-go-back'])

const { t } = useI18n()
const { localePathByType } = useLocalePathByType()

const limits = [
  {
    label: t('voucherActivation.limitReached.physicalVoucher'),
    amount: t('voucherActivation.limitReached.physicalVoucherAmount')
  },
  {
    label: t('voucherActivation.limitReached.digitalVoucher'),
    amount: t('voucherActivation.limitReached.digitalVoucherAmount')
  }
]
</script>
