<template>
  <div class="flex gap-4px">
    <NuxtLink
      v-if="data.image"
      :to="
        localePathByType(
          data?.reference?.__typename as string,
          data?.reference?.slug as string,
          data.reference?.__typename === 'ArticleRecord' ||
            data.reference?.__typename === 'HelpArticleRecord'
            ? (data.reference.category?.slug as string)
            : undefined,
          data.reference?.__typename === 'HelpArticleRecord'
            ? (data.reference.topic?.slug as string)
            : undefined
        )
      "
      class="block"
    >
      <Picture
        class="aspect-3/2 h-[136px] max-w-[184px] overflow-hidden"
        :class="[clipImage && 'block overflow-hidden rounded-t-2xl']"
        pictureClass="w-full h-full"
        imgClass="w-full object-cover h-full"
        :data="data.image"
        loading="lazy"
      />
    </NuxtLink>
    <div class="flex flex-col justify-between">
      <div class="mb-2px text-heading-18-medium-px">{{ data?.title }}</div>
      <NuxtLink
        :to="
          localePathByType(
            data?.reference?.__typename as string,
            data?.reference?.slug as string,
            data.reference?.__typename === 'ArticleRecord' ||
              data.reference?.__typename === 'HelpArticleRecord'
              ? (data.reference.category?.slug as string)
              : undefined,
            data.reference?.__typename === 'HelpArticleRecord'
              ? (data.reference.topic?.slug as string)
              : undefined
          )
        "
        class="text-body-16-regular-px text-neutral-900"
        @click="$emit('on-read-more')"
      >
        {{ data.referenceLabel }}
        <Icon class="h-3px w-3px" name="chevron-right-general" />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VoucherActivationTeaserRecord } from '~/types/generated'

const { localePathByType } = useLocalePathByType()

interface TeaserItemProps {
  data: VoucherActivationTeaserRecord
  clipImage?: boolean
}

withDefaults(defineProps<TeaserItemProps>(), {
  clipImage: false
})

defineEmits(['on-read-more'])
</script>
