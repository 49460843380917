<template>
  <Modal
    variant="basic"
    class="!max-w-[792px] md:mb-8 md:!min-w-[792px]"
    contentClass="!p-4 md:!p-10px"
    :showClose="true"
  >
    <div class="mb-2px pr-10 text-body-18-medium-mobile-px md:mb-4px md:text-heading-22-medium-px">
      {{ $t('voucherActivation.highFees.title') }}
    </div>
    <div class="mb-4px text-body-16-regular-px text-neutral-700 md:mb-8px">
      {{ $t('voucherActivation.highFees.info') }}
    </div>
    <div
      class="mb-7 flex w-full flex-col gap-6 md:mb-6 md:flex-row md:items-center md:justify-between"
    >
      <div class="order-2 md:order-1">
        <MobileDrawer class="md:min-h-[266px]">
          <template #title>{{ $t('voucherActivation.highFees.goodToKnow.title') }}</template>
          <template #content>
            <ul
              class="flex list-disc flex-col gap-3 pl-5 pt-3 text-body-15-regular-mobile-px text-neutral-700 marker:text-purple md:pt-0"
            >
              <i18n-t keypath="voucherActivation.highFees.goodToKnow.first.info" tag="li">
                <template #emphasize>
                  <strong class="font-medium text-black">{{
                    $t('voucherActivation.highFees.goodToKnow.first.keepOnCard')
                  }}</strong>
                </template>
              </i18n-t>
              <i18n-t
                v-if="isMd"
                keypath="voucherActivation.highFees.goodToKnow.second.info"
                tag="li"
              >
                <template #emphasize>
                  <strong class="font-medium text-black">{{
                    $t('voucherActivation.highFees.goodToKnow.second.noFeesOtherWallet')
                  }}</strong>
                </template>
              </i18n-t>
              <i18n-t keypath="voucherActivation.highFees.goodToKnow.third.info" tag="li">
                <template #emphasize>
                  <strong class="font-medium text-black">{{
                    $t('voucherActivation.highFees.goodToKnow.third.waitTime')
                  }}</strong>
                </template>
              </i18n-t>
            </ul>
          </template>
        </MobileDrawer>
      </div>
      <div class="order-1 md:order-2">
        <GaugeFees
          :value="props.fee.toFixed(2)"
          :level="level"
          :label="$t('voucherActivation.highFees.estimatedFees')"
          :asset="asset"
          class="mx-auto"
        />
      </div>
    </div>
    <div class="flex flex-col items-center gap-6 md:flex-row md:justify-between md:gap-4px">
      <div class="order-2 md:order-1">
        <NuxtLink
          :to="
            helpArticleLink &&
            localePathByType(
              helpArticleLink.__typename as string,
              helpArticleLink.slug as string,
              helpArticleLink.category?.slug as string,
              helpArticleLink.topic?.slug as string
            )
          "
          class="text-body-18-regular-mobile-px"
          target="_blank"
        >
          <div class="flex items-center gap-1.5">
            <Icon
              name="info-general"
              class="h-5px w-5px cursor-pointer"
              :color="colors.neutral[600]"
            />
            <div class="text-body-18-regular-mobile-px">
              {{ $t('voucherActivation.highFees.moreAboutBlockchainFees') }}
            </div>
          </div>
        </NuxtLink>
      </div>
      <div class="order-1 flex gap-4px md:order-2">
        <Button
          :label="$t('voucherActivation.highFees.close')"
          :scalable="false"
          variant="outline"
          class="w-full sm:w-fit"
          @click="$emit('on-fees-close')"
        />
        <Button
          :label="$t('voucherActivation.highFees.continue')"
          :scalable="false"
          class="w-full sm:w-fit"
          @click="$emit('on-fees-continue')"
        />
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import colors from '#tailwind-config/theme/colors'
import 'floating-vue/dist/style.css'
import '~/assets/css/tooltips.css'
import screens from '#tailwind-config/theme/screens'
import { HelpArticleRecord, HelpCategoryRecord, HelpTopicRecord, Maybe } from '~/types/generated'

interface Props {
  fee: number
  asset?: 'BTC' | 'ETH'
  helpArticleLink?: Maybe<
    Pick<HelpArticleRecord, '__typename' | 'slug'> & {
      category?: Maybe<Pick<HelpCategoryRecord, 'slug'>>
      topic?: Maybe<Pick<HelpTopicRecord, 'slug'>>
    }
  >
}

const props = defineProps<Props>()
const level = ref(1)
const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')
const { localePathByType } = useLocalePathByType()

const blockchainFeeLevels = {
  bitcoin: [2.8, 4.5, 6, 7, 9],
  ethereum: [1.5, 2.5, 4, 6, 7]
}

const selectedFee = (() => {
  switch (props.asset) {
    case 'ETH':
      return blockchainFeeLevels.ethereum
    case 'BTC':
    default:
      return blockchainFeeLevels.bitcoin
  }
})()

watch(
  () => props.fee,
  () => {
    if (props.fee >= selectedFee[4]) {
      level.value = 5
    } else if (props.fee >= selectedFee[3]) {
      level.value = 4
    } else if (props.fee >= selectedFee[2]) {
      level.value = 3
    } else if (props.fee >= selectedFee[1]) {
      level.value = 2
    } else if (props.fee >= selectedFee[0]) {
      level.value = 1
    }
  },
  { immediate: true }
)

defineEmits(['on-fees-close', 'on-fees-continue'])
</script>
