<template>
  <ModalSplitContent>
    <template #left>
      <canvas ref="canvasRef" class="max-w-full" />
      <div
        v-if="regulatory?.update"
        class="absolute bottom-10px left-10px right-10px rounded-xl bg-white/60 p-4px"
      >
        <div class="text-body-16-medium-px">
          {{ regulatory?.title }}
        </div>
        <div class="mt-2px text-body-16-regular-px text-neutral-800" v-html="regulatory?.html" />
      </div>
      <ActivationTooltip :show="isOpen && isWalletIdFocused" class="left-[217px] w-[104px]">
        {{ $t('voucherActivation.activate.walletId') }}
      </ActivationTooltip>
      <ActivationTooltip :show="isOpen && isActCodeFocused" class="left-[323px] w-[94px]">
        {{ $t('voucherActivation.activate.pinCode') }}
      </ActivationTooltip>
    </template>
    <template #right>
      <form
        class="flex flex-1 flex-col gap-7px md:justify-between md:gap-0"
        name="activation"
        novalidate="true"
        @submit.prevent="handleNext()"
      >
        <div>
          <div class="text-body-27-medium-px">{{ $t('voucherActivation.activate.title') }}</div>
          <div class="mb-6px mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mt-4px">
            {{ $t('voucherActivation.activate.description') }}
          </div>
          <FormCodeInput
            v-model="state.walletid"
            type="text"
            upper-case-only
            :label="$t('voucherActivation.activate.walletId')"
            isRequired
            :isError="!!errorMessage || v$.walletid.$error"
            :showErrorMessage="v$.walletid.$error"
            :errorFixed="false"
            fullWidth
            classField="!mb-6px"
            autoFocus
            @onFocus="isWalletIdFocused = true"
            @onBlur="isWalletIdFocused = false"
            @onFocusNext="() => actCodeRef?.focus()"
          />
          <FormCodeInput
            ref="actCodeRef"
            v-model="state.actcode"
            type="text"
            upper-case-only
            :label="$t('voucherActivation.activate.pinCode')"
            isRequired
            :isError="!!errorMessage || v$.actcode.$error"
            :errorMessage="errorMessage"
            :errorFixed="false"
            fullWidth
            classField="!mb-6px"
            @onFocus="isActCodeFocused = true"
            @onBlur="isActCodeFocused = false"
          />
          <FormTelInput
            v-model="state.mobile"
            :label="$t('voucherActivation.activate.phoneNumber')"
            isRequired
            :isError="v$.mobile.$error"
            :errorMessage="$t('voucherActivation.activate.invalidPhoneNumber')"
            :errorFixed="false"
            classField="!mb-0"
            @change="v$.mobile.$touch"
            @validate="validateNumber"
            @countryChanged="changeCountry"
          />
        </div>
        <ModalActions
          :primary-button="{
            label: $t('voucherActivation.next'),
            loading
          }"
        />
      </form>
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
import { gsap, Linear } from 'gsap'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { FormCodeInputExposed } from '~/components/form/FormCodeInput.vue'
import screens from '#tailwind-config/theme/screens'

const FRAMES = {
  closed: 1,
  opened: 40,
  perSecond: 24
}

interface Props {
  loading?: boolean
  errorMessage?: string
  regulatory?: {
    update: boolean
    title: string
    html: string
  }
}

const props = defineProps<Props>()

const emit = defineEmits(['on-next', 'on-clear-error'])

const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')

const canvasRef = ref<HTMLCanvasElement | null>(null)
const canvasTween = ref<null | any>(null)
const canvas = reactive({ frame: FRAMES.closed })
const frames = ref<HTMLImageElement[]>([])
const isOpen = ref(false)
const isWalletIdFocused = ref(false)
const isActCodeFocused = ref(false)
const phoneValid = ref(false)
const state = reactive({
  country: '',
  mobile: '',
  walletid: '',
  actcode: ''
})
const actCodeRef = ref<FormCodeInputExposed | null>(null)

const rules = {
  mobile: { required: () => (phoneValid.value ? required : false) },
  walletid: { required, minLength: minLength(6) },
  actcode: { required, minLength: minLength(6) }
}
const v$ = useVuelidate(rules, state)

function validateNumber(isNumberValid: boolean) {
  phoneValid.value = isNumberValid
}

function changeCountry(event: any) {
  state.country = event.dialCode
}

const initializeCanvasAndFrames = () => {
  if (!canvasRef.value) {
    return
  }

  frames.value = []
  for (let i = FRAMES.closed; i <= FRAMES.opened; i++) {
    const img = new Image()
    const src1x = new URL(`/assets/images/voucher-activation/voucher_${i}.webp`, import.meta.url)
      .href
    const src2x = new URL(`/assets/images/voucher-activation/voucher_${i}@2x.webp`, import.meta.url)
      .href
    img.src = src1x
    img.srcset = `${src1x} 1x, ${src2x} 2x`
    frames.value.push(img)
  }
  frames.value[0].onload = () => {
    if (!canvasRef.value) {
      return
    }
    const { width, height } = frames.value[0]
    canvasRef.value.width = width
    canvasRef.value.height = height
    renderFrame()
  }
}

const animateFrames = (frame: number, delay?: number) => {
  canvasTween.value = gsap.to(canvas, {
    frame,
    snap: 'frame',
    duration: Math.abs(frame - canvas.frame) / FRAMES.perSecond,
    delay,
    ease: Linear.easeNone,
    onUpdate: renderFrame
  })
}

const renderFrame = () => {
  if (!canvasRef.value) {
    return
  }
  const context = canvasRef.value?.getContext('2d')
  context?.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height)
  context?.drawImage(frames.value[canvas.frame - FRAMES.closed], 0, 0)
  isOpen.value = canvas.frame === FRAMES.opened
}

onMounted(() => {
  initializeCanvasAndFrames()

  setTimeout(() => {
    animateFrames(FRAMES.opened)
  }, 2000)
})

watch(
  () => isMd.value,
  () => {
    if (isMd.value) {
      setTimeout(initializeCanvasAndFrames)
    }
  }
)

watch(
  () => [isWalletIdFocused.value, isActCodeFocused.value],
  ([isWalletIdFocused, isActCodeFocused], [isWalletIdFocusedOld, isActCodeFocusedOld]) => {
    if (!canvasRef.value || !canvasTween.value) {
      return
    }
    canvasTween.value.kill()
    if (
      (isWalletIdFocused && !isWalletIdFocusedOld) ||
      (isActCodeFocused && !isActCodeFocusedOld)
    ) {
      animateFrames(FRAMES.opened)
    } else if (!isWalletIdFocused && !isActCodeFocused) {
      animateFrames(FRAMES.closed, 0.5)
    }
  }
)

watch(
  () => [state.walletid, state.actcode],
  () => {
    if (props.errorMessage) {
      emit('on-clear-error')
    }
  }
)

const handleNext = async () => {
  const isFormInvalid = await v$.value.$validate()
  if (!isFormInvalid) {
    return
  }
  const country = `+${state.country}`
  emit('on-next', {
    ...state,
    country,
    mobile: state.mobile.replace(country, '')
  })
}
</script>
