<template>
  <ModalSplitContent>
    <template #left>
      <Image
        :src="image"
        :width="476"
        :height="616"
        loading="lazy"
        class="object-cover object-[center_40px]"
      />
    </template>
    <template #right>
      <form
        class="flex flex-1 flex-col gap-7px md:justify-between md:gap-0"
        name="exchange-crypto"
        novalidate="true"
        @submit.prevent="handleExchange()"
      >
        <div>
          <div class="text-body-27-medium-px">
            {{ $t('voucherActivation.exchangeCrypto.title') }}
          </div>
          <div class="mb-6px mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mt-4px">
            {{ $t('voucherActivation.exchangeCrypto.description') }}
          </div>
          <ConversionBox
            v-if="crypto"
            :sourceAmount="useLocaleCurrency(priceResponse?.vouchervalue) ?? ''"
            :target="{
              amount: priceResponse?.exchangevalue
                ? `${priceResponse?.exchangevalue} ${crypto.symbol.toLocaleUpperCase()}`
                : '',
              image: crypto.image,
              price: useLocaleCurrency(priceResponse?.marketprice) ?? ''
            }"
            :color="cryptoColor"
          />
          <div class="mt-6px flex flex-col gap-4px">
            <FormCheckbox
              v-model="state.agree"
              name="agree"
              :isError="v$.agree.$error"
              @change="v$.agree.$touch"
            >
              <i18n-t keypath="voucherActivation.exchangeCrypto.agree" tag="span" scope="global">
                <template #termsAndConditions>
                  <NuxtLink
                    :to="localePath('/' + termsAndConditionsLink)"
                    class="font-medium hover:no-underline"
                    target="_blank"
                  >
                    {{ $t('voucherActivation.exchangeCrypto.termsAndConditions') }}
                  </NuxtLink>
                </template>
                <template #exchangeConditions>
                  <NuxtLink
                    :to="localePath('/' + exchangeConditionsLink)"
                    class="font-medium hover:no-underline"
                    target="_blank"
                  >
                    {{ $t('voucherActivation.exchangeCrypto.exchangeConditions') }}
                  </NuxtLink>
                </template>
              </i18n-t>
            </FormCheckbox>
            <FormCheckbox
              v-model="state.smsReceipt"
              name="smsReceipt"
              :label="$t('voucherActivation.exchangeCrypto.smsReceipt')"
            />
          </div>
        </div>
        <ModalActions
          :primary-button="{
            label: $t('voucherActivation.exchangeCrypto.exchange'),
            loading
          }"
          :secondary-button="{
            label: $t('voucherActivation.exchangeCrypto.feesAndComissions'),
            onClick: () => $emit('on-fees-and-comissions'),
            class: 'text-neutral-700'
          }"
        />
      </form>
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { sameAs } from '@vuelidate/validators'
import { GetPriceResponse } from '~/types/exchange'
import { DEFAULT_CRYPTO, ASSET_COLORS, Crypto } from '~/data/cryptoMarket'
import { useCryptoMarketStore } from '~/stores/CryptoMarketStore'
import image from '~/assets/images/content/universal-vertical.png'

interface Props {
  loading: boolean
  walletId: string
  termsAndConditionsLink: string
  exchangeConditionsLink: string
}

const localePath = useLocalePath()
const props = defineProps<Props>()

const emit = defineEmits(['on-exchange', 'on-fees-and-comissions', 'on-timeout'])

const { getPrice } = useExchangeAPI()
const cryptoMarketStore = useCryptoMarketStore()
const priceResponse = ref<GetPriceResponse>()

const state = reactive({
  agree: false,
  smsReceipt: false
})
const rules = {
  agree: { sameAs: sameAs(true) }
}
const v$ = useVuelidate(rules, state)

const crypto = computed(
  () =>
    cryptoMarketStore.getCoinsBySymbol(
      priceResponse.value?.crypto.toLocaleLowerCase() ?? DEFAULT_CRYPTO
    )[0]
)
const cryptoColor = computed(() => ASSET_COLORS[(crypto.value?.id as Crypto) ?? DEFAULT_CRYPTO])

const { stop } = useTimeoutFn(() => emit('on-timeout'), 1000 * 60 * 5, { immediate: true }) // 5 minutes

const { pause } = useIntervalFn(
  async () => {
    const { data } = await getPrice({ id: props.walletId })
    if (data.value) {
      priceResponse.value = data.value
    }
  },
  1000 * 5, // 5 seconds
  {
    immediate: true,
    immediateCallback: true
  }
)

onUnmounted(() => {
  stop()
  pause()
})

const handleExchange = async () => {
  const isFormInvalid = await v$.value.$validate()
  if (!isFormInvalid) {
    return
  }
  emit('on-exchange', { smsReceipt: state.smsReceipt })
}
</script>
