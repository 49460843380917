<template>
  <div class="flex h-full flex-col justify-between md:p-10px">
    <div>
      <div class="text-body-27-medium-px">{{ $t('voucherActivation.giveFeedback.title') }}</div>
      <div class="mb-6px mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mt-4px">
        {{ $t('voucherActivation.giveFeedback.description') }}
      </div>
      <FormRating
        v-model="state.rating"
        isRequired
        classField="!mb-6px"
        :label="$t('voucherActivation.giveFeedback.ratingLabel')"
        :leftLabel="$t('voucherActivation.giveFeedback.notLikely')"
        :rightLabel="$t('voucherActivation.giveFeedback.veryLikely')"
        :isError="v$.rating.$error"
        @change="v$.rating.$touch"
      />
      <FormTextarea
        v-model="state.message"
        class="h-[160px] md:h-[104px]"
        classField="!mb-6px md:!mb-0"
        :label="$t('voucherActivation.giveFeedback.messageLabel')"
        :placeholder="$t('voucherActivation.giveFeedback.messagePlaceholder')"
      />
      <div class="grid-default">
        <FormSelect
          v-model="state.source"
          classField="!mb-7px md:!mb-0 col-span-6"
          :options="topicOptions"
          :label="$t('voucherActivation.giveFeedback.sourceLabel')"
          :placeholder="$t('forms.selectPlaceholder')"
          isRequired
          :isError="v$.source.$error"
          @change="v$.source.$touch"
        />
      </div>
    </div>
    <ModalActions
      class="pb-7px md:pb-0"
      :primary-button="{
        label: $t('voucherActivation.giveFeedback.send'),
        loading,
        onClick: handleSend
      }"
    />
  </div>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const emit = defineEmits(['on-success'])

const loading = ref(false)
const state = reactive({
  rating: undefined,
  message: '',
  source: ''
})

const rules = {
  rating: { required },
  source: { required }
}
const v$ = useVuelidate(rules, state)

const { tm, rt } = useI18n()

const topicOptions = computed(() =>
  // @ts-ignore
  tm('forms.fields.topicOptions').map((option: any) => {
    return {
      label: rt(option),
      value: formatAsLink(rt(option))
    }
  })
)

const handleSend = async () => {
  const isFormInvalid = await v$.value.$validate()
  if (!isFormInvalid) {
    return
  }

  loading.value = true
  // TODO: send feedback request
  emit('on-success')
  loading.value = false
}
</script>
