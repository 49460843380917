export interface GetNonceResponse {
  nonce: string
}

export interface ActivateRequest {
  nonce: string
  country: string
  mobile: string
  walletid: string
  actcode: string
}

export interface ActivateResponse {
  nonce: string
}

export interface VerifyRequest {
  nonce: string
  walletid: string
  mtan: string
}

export interface ExchangeRequest {
  nonce: string
  walletid: string
  email: string
  sendreceipt: boolean
}

export interface ExchangeResponse {
  crypto: string
  mobile: string
  fiat: string
  vouchervalue: number
  marketprice: number | string
  exchangevalue: number
}

export interface GetVoucherResponse {
  crypto: string
}

export interface GetPriceResponse {
  crypto: string
  fiat: string
  vouchervalue: number
  marketprice: number
  exchangevalue: number
}

export interface GetFeeResponse {
  fixfee: number
  commission: number
}

export enum ExchangeErrorCode {
  VOUCHER_INVALID_CODE = 'wallet.invalidcode',
  VOUCHER_NOT_FOUND = 'wallet.none',
  VOUCHER_USED = 'wallet.used',
  VOUCHER_EXPIRED = 'wallet.expired',
  VOUCHER_INACTIVE = 'wallet.notactive',
  VOUCHER_LOCKED_ADMIN = 'wallet.locked.admin',
  VOUCHER_LOCKED_MTAN = 'wallet.locked.mtan',
  VOUCHER_LOCKED_PIN_CODE = 'wallet.locked.actcode',

  MTAN_INVALID_NUMBER = 'mtan.number.invalid',
  MTAN_INVALID_CODE = 'mtan.code.invalid',
  MTAN_EXPIRED = 'mtan.expire',

  NONCE_ERROR = 'nonce.error',
  ACTIVATION_TIMEOUT = 'activation.timeout',
  SERVER_ERROR_VARDEX = 'server.error.vardex',
  EXCHANGE_LIMIT = 'exchange.limit',
  SHOP_NOT_FOUND = 'shop.none',

  DOS_IPBLOCK = 'dos.ipblock',
  DOS_RATE = 'dos.rate',

  BITSTAMP_BALANCE_LOW = 'bitstamp.balance.low',
  BITSTAMP_OFFLINE = 'bitstamp.offline'
}

export interface ExchangeErrorResponse {
  mKey: ExchangeErrorCode
  level: string
  message: string
}
