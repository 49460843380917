<template>
  <ModalSplitContent>
    <template #left>
      <div class="flex w-full flex-col items-center">
        <div class="relative mt-6px flex-1">
          <Image :src="phoneImage" :width="476" :height="500" loading="lazy" />
          <Image
            :src="mtanPhoneScreenImage"
            :width="476"
            :height="500"
            loading="lazy"
            class="absolute top-0"
          />
        </div>
        <i18n-t
          :keypath="
            isInvalidCode
              ? 'voucherActivation.verifyIdentity.codeInvalid'
              : 'voucherActivation.verifyIdentity.codeValidTime'
          "
          tag="div"
          class="mb-10px mt-6px text-body-18-regular-mobile-px text-neutral-700"
          scope="global"
        >
          <template #time>
            <span class="text-body-18-medium-mobile-px text-orange">{{ time }}</span>
          </template>
        </i18n-t>
      </div>
    </template>
    <template #right>
      <form
        class="flex flex-1 flex-col gap-7px md:justify-between md:gap-0"
        name="verify-identity"
        novalidate="true"
        @submit.prevent="handleNext()"
      >
        <div>
          <div class="text-body-27-medium-px">
            {{ $t('voucherActivation.verifyIdentity.title') }}
          </div>
          <div
            class="mb-4px mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mb-6px md:mt-4px"
          >
            {{ $t('voucherActivation.verifyIdentity.description') }}
          </div>
          <i18n-t
            v-if="!isInvalidCode"
            keypath="voucherActivation.verifyIdentity.codeValidTimeShort"
            tag="div"
            class="text-body-18-regular-mobile-px text-neutral-700 md:hidden"
            scope="global"
          >
            <template #time>
              <span class="text-body-18-medium-mobile-px text-orange">{{ time }}</span>
            </template>
          </i18n-t>
          <FormCodeInput
            ref="codeInputRef"
            v-model="state.mTanCode"
            :label="$t('voucherActivation.verifyIdentity.mtanCode')"
            isRequired
            classField="!mb-0 mt-10px md:mt-6px"
            :isError="(!!errorMessage || v$.mTanCode.$error) && !isExpired"
            :errorMessage="errorMessage"
            :errorFixed="false"
            fullWidth
            :isDisabled="isFormDisabled"
            autoFocus
          />
          <template v-if="!isLocked">
            <NoticeBox v-if="isFormDisabled" class="mt-6px" background="orange">
              <i18n-t
                :keypath="
                  isInvalidCode
                    ? 'voucherActivation.verifyIdentity.invalidCode'
                    : 'voucherActivation.verifyIdentity.expiredCode'
                "
                tag="div"
                class="text-body-15-regular-mobile-px text-neutral-700"
                scope="global"
              >
                <template #resendCode>
                  <ResendCode
                    class="text-body-15-medium-mobile-px"
                    :sent="isSent"
                    @click="handleResend"
                  />
                </template>
              </i18n-t>
            </NoticeBox>
            <div v-else class="mt-6px hidden gap-1px text-body-16-regular-px md:flex">
              <span class="text-neutral-700">
                {{ $t('voucherActivation.verifyIdentity.didntReceive') }}
              </span>
              <ResendCode :sent="isSent" @click="handleResend" />
            </div>
          </template>
        </div>
        <ModalActions
          :primary-button="{
            label: $t('voucherActivation.next'),
            loading,
            disabled: isFormDisabled,
            isDisabled: isFormDisabled
          }"
        >
          <template v-if="!isFormDisabled && !isLocked" #secondary>
            <div class="flex justify-center gap-1px text-body-16-regular-px md:hidden">
              <span class="text-neutral-700">
                {{ $t('voucherActivation.verifyIdentity.didntReceive') }}
              </span>
              <ResendCode :sent="isSent" @click="handleResend" />
            </div>
          </template>
        </ModalActions>
      </form>
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import phoneImage from '~/assets/images/content/phone.png'
import mtanPhoneScreenImage from '~/assets/images/content/mtan-phone-screen.png'
import { FormCodeInputExposed } from '~/components/form/FormCodeInput.vue'
import { ExchangeErrorCode } from '~/types/exchange'

interface Props {
  expirationDate: Date
  loading?: boolean
  errorMessage?: string
  errorCode?: ExchangeErrorCode
}

const props = defineProps<Props>()

const emit = defineEmits(['on-next', 'on-resend', 'on-clear-error'])

const codeInputRef = ref<FormCodeInputExposed | null>(null)
const isSent = ref(false)
const isExpired = ref(false)
const attemptNumber = ref(1)
const time = ref('')
const state = reactive({
  mTanCode: ''
})
const rules = {
  mTanCode: { required, minLength: minLength(6) }
}
const v$ = useVuelidate(rules, state)

const updateTime = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  time.value = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

const { pause, resume, isActive } = useIntervalFn(
  () => {
    const totalSeconds = Math.max(
      Math.floor((props.expirationDate.getTime() - Date.now()) / 1000),
      0
    )
    updateTime(totalSeconds)
    isExpired.value = totalSeconds === 0
  },
  1000,
  {
    immediate: true,
    immediateCallback: true
  }
)

const isLocked = computed(() => props.errorCode === ExchangeErrorCode.VOUCHER_LOCKED_MTAN)
const isInvalidCode = computed(
  () => !!props.errorMessage && (attemptNumber.value === 3 || isLocked.value)
)
const isFormDisabled = computed(() => isExpired.value || isInvalidCode.value || isLocked.value)

watch(
  () => state.mTanCode,
  () => {
    if (props.errorMessage) {
      emit('on-clear-error')
    }
  }
)

watch(
  () => [isExpired.value, isInvalidCode.value],
  () => {
    if (isActive.value && (isExpired.value || isInvalidCode.value)) {
      isSent.value = false
      pause()
    }
  }
)

watch(
  () => props.expirationDate,
  () => {
    if (!isActive.value && props.expirationDate.getTime() > Date.now()) {
      resume()
    }
  }
)

watch(
  () => [Math.floor(props.expirationDate.getTime() / 1000), Math.floor(Date.now() / 1000)],
  ([expirationTime, nowTime]) => {
    const totalSeconds = Math.max(expirationTime - nowTime, 0)
    updateTime(totalSeconds)
  }
)

onUnmounted(() => {
  pause()
})

const handleResend = () => {
  isSent.value = true
  codeInputRef.value?.clear()
  v$.value.$reset()
  emit('on-resend')
  attemptNumber.value = 1
}

const handleNext = async () => {
  const isFormInvalid = await v$.value.$validate()
  if (!isFormInvalid) {
    return
  }
  emit('on-next', {
    mTanCode: state.mTanCode
  })
  attemptNumber.value++
}
</script>
