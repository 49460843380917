<template>
  <FormField
    :class="classField"
    :label="label"
    :isRequired="isRequired"
    :isLabelVisible="isLabelVisible"
    :isError="isError && showErrorMessage"
    :errorFixed="errorFixed"
    :errorMessage="errorMessage"
  >
    <div className="flex w-fit space-x-3">
      <input
        v-for="(_, i) in Array.from({ length: numberOfFields })"
        ref="inputRefs"
        :key="i"
        type="text"
        :disabled="isDisabled"
        :class="{
          inputPurple: style === 'purple',
          isError,
          isDisabled
        }"
        :autocomplete="i === 0 ? 'one-time-code' : 'off'"
        :value="inputValues[i]"
        :style="{ width: fullWidth ? '100%' : `calc(${numberOfCharactersPerField}rem + 32px)` }"
        class="min-w-0 px-0 text-center"
        v-bind="$attrs"
        @input="handleInput(i, $event)"
        @keydown.delete="handleDeleteKeydown(i, $event)"
        @keydown.left="handleLeftKeydown(i)"
        @keydown.right="handleRightKeydown(i)"
        @keydown="handleKeydown"
        @paste="handlePaste"
        @focus="handleFocus(true)"
        @blur="handleFocus(false)"
      />
    </div>
  </FormField>
</template>

<script setup lang="ts">
export interface FormCodeInputExposed {
  clear: () => void
  focus: () => void
}

export type InputStyles = 'gray' | 'purple'

interface FormCodeInputProps {
  type?: 'text' | 'number'
  upperCaseOnly?: boolean
  classField?: string
  label: string
  isLabelVisible?: boolean
  isRequired?: boolean
  modelValue: string
  isDisabled?: boolean
  isError?: boolean
  errorFixed?: boolean
  errorMessage?: string
  showErrorMessage?: boolean
  style?: InputStyles
  numberOfFields?: number
  numberOfCharactersPerField?: number
  fullWidth?: boolean
  autoFocus?: boolean
}

const props = withDefaults(defineProps<FormCodeInputProps>(), {
  type: 'number',
  isLabelVisible: true,
  modelValue: '',
  errorFixed: true,
  showErrorMessage: true,
  style: 'gray',
  numberOfFields: 6,
  numberOfCharactersPerField: 1
})

const emit = defineEmits(['update:modelValue', 'onFocus', 'onBlur', 'onFocusNext'])

defineOptions({
  inheritAttrs: false
})

const inputRefs = ref<HTMLInputElement[]>([])
const inputValues = ref<string[]>(Array(props.numberOfFields).fill(''))
const isFocused = ref(false)

watch(
  () => props.numberOfFields,
  () => {
    inputValues.value = Array(props.numberOfFields).fill('')
  }
)

function isNumeric(str: unknown) {
  if (typeof str !== 'string') {
    return false
  }
  return !isNaN(str as unknown as number) && !isNaN(parseFloat(str))
}

const handleInput = (index: number, event: Event) => {
  if (!event.target) {
    return
  }

  let { value } = event.target as HTMLInputElement
  if (props.upperCaseOnly) {
    value = value.toLocaleUpperCase()
  }
  const newInputValues = [...inputValues.value]
  const newValue = value.slice(0, props.numberOfCharactersPerField)
  if (
    (props.type === 'number' && isNumeric(newValue)) ||
    props.type === 'text' ||
    newValue === ''
  ) {
    newInputValues[index] = newValue
    const newJointValue = newInputValues.join('')
    emit('update:modelValue', newJointValue)
    if (newValue.length === props.numberOfCharactersPerField && value !== '') {
      if (index < inputRefs.value.length - 1) {
        inputRefs.value[index + 1].focus()
      } else {
        emit('onFocusNext')
      }
    }
  }
  inputValues.value = newInputValues
}

const handleDeleteKeydown = (index: number, event: Event) => {
  const { value } = event.target as HTMLInputElement
  if (value === '' && index > 0) {
    inputRefs.value[index - 1].focus()
  }
}

const handleLeftKeydown = (index: number) => {
  if (index > 0) {
    inputRefs.value[index - 1].focus()
  }
}

const handleRightKeydown = (index: number) => {
  if (index < inputRefs.value.length - 1) {
    inputRefs.value[index + 1].focus()
  }
}

const handleKeydown = (event: KeyboardEvent) => {
  if (!event.key.match(/^[a-zA-Z0-9]+$/)) {
    event.preventDefault()
  }
}

const handlePaste = (event: ClipboardEvent) => {
  const clipboardData =
    event.clipboardData ||
    (window as any).clipboardData ||
    (event as any).originalEvent?.clipboardData
  let pastedText = clipboardData.getData('text')
  if (props.type === 'number') {
    pastedText = pastedText.replace(/\D/g, '')
  } else {
    pastedText = pastedText.replace(/[^A-Za-z0-9]/g, '')
  }
  if (props.upperCaseOnly) {
    pastedText = pastedText.toLocaleUpperCase()
  }
  const newValues = pastedText
    .match(new RegExp('.{1,' + props.numberOfCharactersPerField + '}', 'g'))
    ?.slice(0, props.numberOfFields)
  if (newValues) {
    inputValues.value = [...newValues]
    inputRefs.value[newValues ? newValues.length - 1 : 0].focus()
  }
}

const handleFocus = useDebounceFn((focused: boolean) => {
  if (isFocused.value !== focused) {
    isFocused.value = focused
    focused ? emit('onFocus') : emit('onBlur')
  }
})

const clear = () => {
  inputValues.value = Array(props.numberOfFields).fill('')
  isFocused.value = false
  emit('update:modelValue', '')
}

const focus = () => {
  inputRefs.value[0].focus()
}

defineExpose({
  clear,
  focus
})

onMounted(() => {
  if (props.autoFocus) {
    inputRefs.value[0].focus()
  }
})
</script>
