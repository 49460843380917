<template>
  <ModalSplitContent>
    <template #left>
      <RateUsContent v-bind="rating" />
    </template>
    <template #right>
      <div>
        <div class="md:hidden">
          <RateUsContent v-bind="rating" />
          <hr class="my-6px h-px border-none bg-neutral-100" />
        </div>
        <div class="mb-6px text-heading-18-medium-px md:mb-8px">
          {{ $t('voucherActivation.rateUs.followSteps') }}
        </div>
        <RateUsStep
          :step="1"
          :text="$t('voucherActivation.rateUs.leaveReview')"
          class="mb-6px md:mb-8px"
        >
          <div class="flex gap-4px">
            <!-- TODO: link to google review -->
            <ReviewUsBox to="google-review">
              <Image :src="googleImage" :height="24" :width="71" />
            </ReviewUsBox>
            <!-- TODO: link to trustpilot review -->
            <ReviewUsBox to="trustpilot-review">
              <Image :src="trustpilotImage" :height="24" :width="87" />
            </ReviewUsBox>
          </div>
        </RateUsStep>
        <RateUsStep :step="2" :text="$t('voucherActivation.rateUs.enterName')">
          <FormInput
            v-model="state.name"
            classField="!mb-6px md:!mb-8px"
            :label="$t('voucherActivation.rateUs.nameOrUsername')"
            :placeholder="$t('voucherActivation.rateUs.nameOrUsername')"
            :isLabelVisible="false"
            isRequired
            :isError="v$.name.$error"
            :errorFixed="false"
            @change="v$.name.$touch"
          />
        </RateUsStep>
        <FormCheckbox
          v-model="state.agree"
          name="agree"
          classLabel="!text-[15px] opacity-70"
          :label="$t('voucherActivation.rateUs.agree')"
          :isError="v$.agree.$error"
          @change="v$.agree.$touch"
        />
      </div>
      <ModalActions
        :primary-button="{
          label: $t('voucherActivation.rateUs.joinContest'),
          onClick: handleJoinContest
        }"
      />
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'
import googleImage from '~/assets/images/content/google.svg'
import trustpilotImage from '~/assets/images/content/trustpilot.svg'
import { RatingProps } from '~/components/voucher-activation/components/RateUsContent.vue'

interface Props {
  rating: RatingProps
}

defineProps<Props>()

const loading = ref(false)
const state = reactive({
  name: '',
  agree: false
})

const rules = {
  name: { required },
  agree: { sameAs: sameAs(true) }
}
const v$ = useVuelidate(rules, state)

const emit = defineEmits(['on-success'])

const handleJoinContest = async () => {
  const isFormInvalid = await v$.value.$validate()
  if (!isFormInvalid) {
    return
  }

  loading.value = true
  // TODO: send join contest request
  emit('on-success')
  loading.value = false
}
</script>
