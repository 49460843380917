<template>
  <ModalSplitContent>
    <template #left>
      <Image :src="image" :width="476" :height="616" loading="lazy" />
    </template>
    <template #right>
      <div>
        <div class="text-body-27-medium-px text-orange">
          {{ $t('voucherActivation.generalError.title') }}
        </div>
        <div class="mb-6px mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mt-4px">
          {{ description || $t('voucherActivation.generalError.description') }}
        </div>
        <hr class="mb-6px h-px border-none bg-neutral-100" />
        <div
          class="mb-6px text-body-16-regular-px text-neutral-700"
          v-html="$t('voucherActivation.generalError.message')"
        />
        <NoticeBox>
          <i18n-t keypath="voucherActivation.generalError.info" tag="div" scope="global">
            <template #contactUs>
              <NuxtLink
                :to="
                  contactUsLink
                    ? localePathByType(
                        contactUsLink.__typename as string,
                        contactUsLink.slug as string
                      )
                    : undefined
                "
                class="text-body-16-medium-px text-black"
                target="_blank"
              >
                {{ $t('voucherActivation.generalError.contactUs') }}
              </NuxtLink>
            </template>
          </i18n-t>
        </NoticeBox>
      </div>
      <ModalActions
        :primary-button="{
          label: $t('voucherActivation.generalError.tryAgain'),
          onClick: () => $emit('on-try-again')
        }"
      />
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
import image from '~/assets/images/content/universal-left.png'
import { Maybe, PageRecord } from '~/types/generated'

interface Props {
  description: string
  contactUsLink?: Maybe<Pick<PageRecord, '__typename' | 'slug'>>
}

defineProps<Props>()

defineEmits(['on-try-again', 'on-close'])

const { localePathByType } = useLocalePathByType()
</script>
