<template>
  <div class="w-full rounded-xl bg-neutral-50 p-4 md:max-w-[416px] md:p-5px">
    <div class="relative text-body-16-medium-px text-black md:mb-3">
      <button type="button" class="block w-full text-left" :disabled="isMd" @click="expand">
        <slot name="title" />
      </button>
      <Icon
        v-if="!isMd"
        name="chevron-down-general"
        class="absolute right-0 top-1 h-4px w-4px md:hidden"
        :class="isExpanded ? 'rotate-180' : 'rotate-0'"
      />
    </div>
    <Transition
      name="mobile-drawer"
      @enter="setHeight"
      @afterEnter="unsetHeight"
      @beforeLeave="setHeight"
      @afterLeave="unsetHeight"
    >
      <div
        v-show="isMd ? true : isExpanded"
        :key="key"
        class="md:!h-auto md:!opacity-100 md:!duration-0"
      >
        <slot name="content" />
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import screens from '#tailwind-config/theme/screens'

const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')

const key = ref(0)
const isExpanded = ref(false)
const isAnimating = ref(false)

const expand = () => {
  if (isAnimating.value) {
    return
  }
  isExpanded.value = !isExpanded.value
}

function setHeight(el: HTMLElement) {
  isAnimating.value = true
  el.style.height = el.scrollHeight + 'px'
}

function unsetHeight(el: HTMLElement) {
  isAnimating.value = false
  el.style.height = ''
}
</script>

<style lang="postcss">
.mobile-drawer-enter-active,
.mobile-drawer-leave-active {
  will-change: height, opacity;
  transition:
    height 300ms ease-out,
    opacity 300ms ease-out;
  overflow: hidden;
}

.mobile-drawer-enter-from,
.mobile-drawer-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
