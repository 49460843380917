<template>
  <div>
    <Modal
      :show="store.showVoucherActivation"
      variant="with-topbar"
      :steps="{ current: step, total: 3 }"
      :showSteps="step !== -1"
      @onClose="handleClose"
    >
      <div class="relative h-full">
        <TransitionGroup name="fadeInOut">
          <Activation
            v-if="content === Content.Activation"
            key="activation"
            :loading="loading"
            :errorMessage="errorMessage"
            :regulatory="{
              update: !!cmsData?.voucherActivation?.regulatoryUpdate,
              title: cmsData?.voucherActivation?.regulatoryTitle ?? '',
              html: cmsData?.voucherActivation?.regulatoryHtml ?? ''
            }"
            @onNext="handleActivate"
            @onClearError="handleClearError"
          />
          <ActivationLimitReached
            v-else-if="content === Content.ActivationLimitReached"
            key="activation-limit-reached"
            :helpArticleLink="cmsData?.voucherActivation?.limitHelpArticle"
            @onClose="handleClose"
            @onGoBack="handleBackToActivation"
          />
          <GeneralError
            v-else-if="content === Content.GeneralError"
            key="general-error"
            :description="errorMessage"
            :contactUsLink="cmsData?.voucherActivation?.errorContactLink"
            @onClose="handleClose"
            @onTryAgain="handleBackToActivation"
          />
          <VerifyIdentity
            v-else-if="content === Content.VerifyIdentity"
            key="verify-identity"
            :loading="loading"
            :errorMessage="errorMessage"
            :errorCode="errorCode"
            :expirationDate="expirationDate!"
            @onNext="handleVerifyIdentity"
            @onResend="handleResendMtanCode"
            @onClearError="handleClearError"
          />
          <ExchangeCrypto
            v-else-if="content === Content.ExchangeCrypto"
            key="exchange-crypto"
            :loading="loading"
            :walletId="activateRequestForm?.walletid!"
            :termsAndConditionsLink="cmsData?.voucherActivation?.exchangeTermsLink?.slug as string"
            :exchangeConditionsLink="
              cmsData?.voucherActivation?.exchangeConditionsLink?.slug as string
            "
            @onExchange="handleExchangeCrypto"
            @onFeesAndComissions="() => (content = Content.FeesAndCommissions)"
            @onTimeout="handleBackToActivation()"
          />
          <FeesAndComissions
            v-else-if="content === Content.FeesAndCommissions"
            key="fees-and-comissions"
            :walletId="activateRequestForm?.walletid!"
            :feesLinkTo="cmsData?.voucherActivation?.feesLink?.slug as string"
            @onGoBack="() => (content = Content.ExchangeCrypto)"
          />
          <!-- For testing purpose import MockExchangeResponse from mockData and pass as prop:
          :exchangeResponse="MockExchangeResponse" -->
          <ActivationSuccess
            v-else-if="content === Content.ActivationSuccess"
            key="activation-success"
            :walletId="activateRequestForm?.walletid!"
            :exchangeResponse="exchangeResponse!"
            :showSmsReceipt="sendReceipt"
            :showRateUs="!!cmsData?.voucherActivation?.ratingShow"
            :teasers="cmsData?.voucherActivation?.teasers as VoucherActivationTeaserRecord[]"
            @onClose="handleClose"
            @onFinish="handleClose"
            @onGiveFeedback="() => (content = Content.GiveFeedback)"
            @onRateUs="handleRateUs($event)"
          />
          <GiveFeedback
            v-else-if="content === Content.GiveFeedback"
            key="give-feedback"
            @onSuccess="() => (content = Content.GiveFeedbackSuccess)"
          />
          <GiveFeedbackSuccess
            v-else-if="content === Content.GiveFeedbackSuccess"
            key="give-feedback-success"
            :contactUsLink="cmsData?.voucherActivation?.errorContactLink"
            :showView="rateSuccessView"
            @onClose="handleClose"
          />
          <RateUs
            v-else-if="content === Content.RateUs"
            key="rate-us"
            :rating="{
              title: cmsData?.voucherActivation?.ratingTitle ?? '',
              html: cmsData?.voucherActivation?.ratingHtml ?? '',
              image: cmsData?.voucherActivation?.ratingImage as FileField,
              deadline: cmsData?.voucherActivation?.ratingDeadline ?? ''
            }"
            @onSuccess="() => (content = Content.RateUsSuccess)"
          />
          <RateUsSuccess
            v-else-if="content === Content.RateUsSuccess"
            key="rate-us-success"
            @onClose="handleClose"
          />
        </TransitionGroup>
      </div>
    </Modal>
    <VoucherActivationExitModal :show="showExit" @onClose="showExit = false" @onExit="handleExit" />
    <VoucherActivationHighFeesModal
      :fee="blockchainFee"
      :asset="selectedBlockchain"
      :show="showHighFees"
      :helpArticleLink="cmsData?.voucherActivation?.blockchainFeesHelpArticle"
      @on-fees-close="showHighFees = false"
      @on-fees-continue="handleAcceptHighFees"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ActivateRequest,
  ExchangeErrorResponse,
  ExchangeResponse,
  ExchangeErrorCode
} from '~/types/exchange'
import { useModalStore } from '~/stores/ModalStore'
import { FileField, VoucherActivationQuery, VoucherActivationTeaserRecord } from '~/types/generated'
import { BlockchainFees } from '~/types/feeder'

enum Content {
  Activation,
  ActivationLimitReached,
  GeneralError,
  VerifyIdentity,
  ExchangeCrypto,
  FeesAndCommissions,
  ActivationSuccess,
  GiveFeedback,
  GiveFeedbackSuccess,
  RateUs,
  RateUsSuccess
}

type ActivateRequestForm = Pick<ActivateRequest, 'country' | 'mobile' | 'walletid' | 'actcode'>

const route = useRoute()
const { localeDato } = useLanguage()
const { getNonce, getVoucher, activate, verify, exchange } = useExchangeAPI()
const { getConversion, getFees } = useFeederAPI()
const store = useModalStore()
const content = ref<Content>(Content.Activation)
const showExit = ref(false)
const showHighFees = ref(false)
const loading = ref(false)
const errorMessage = ref('')
const errorCode = ref<ExchangeErrorCode>()
const nonce = ref('')
const expirationDate = ref(new Date())
const blockchainFee = ref(0)
const blockchainFees = ref<BlockchainFees>()
const selectedBlockchain = ref<'BTC' | 'ETH'>('BTC')
const sendReceipt = ref(false)
const activateRequestForm = ref<ActivateRequestForm>()
const exchangeResponse = ref<ExchangeResponse>()
const rateSuccessView = ref(1)

const cmsData = ref<VoucherActivationQuery>()

const step = computed(() => {
  switch (content.value) {
    case Content.Activation:
      return 1
    case Content.VerifyIdentity:
      return 2
    case Content.ExchangeCrypto:
      return 3
    default:
      return -1
  }
})

const resetNonce = async () => {
  const { data } = await getNonce()
  if (data.value) {
    nonce.value = data.value.nonce
  } else {
    content.value = Content.GeneralError
  }
}

const handleFee = async () => {
  const fee = await getFees()
  if (fee.data.value) {
    blockchainFees.value = fee.data.value
  }
}

const convertFee = (fee: number, conversion: any) => {
  return (fee / conversion.data.value.rates.USD) * conversion.data.value.rates.CHF
}

const activateVoucher = async (reqForm: ActivateRequestForm) => {
  loading.value = true
  const { data, error } = await activate({
    nonce: nonce.value,
    ...reqForm
  })
  if (data.value) {
    nonce.value = data.value.nonce
    expirationDate.value = new Date(Date.now() + 5 * 60 * 1000)
    content.value = Content.VerifyIdentity
  } else if (error.value) {
    await handleError(error.value.data)
  }
  loading.value = false
}

const handleActivate = async (reqForm: ActivateRequestForm) => {
  loading.value = true
  activateRequestForm.value = reqForm
  if (blockchainFees.value) {
    const [voucher, conversion] = await Promise.all([
      getVoucher({ walletId: reqForm.walletid }),
      getConversion()
    ])
    if (voucher.data.value?.crypto === 'BTC') {
      blockchainFee.value = convertFee(
        blockchainFees.value.bitcoin.average_transaction_fee_usd_24h,
        conversion
      )
      selectedBlockchain.value = 'BTC'
      showHighFees.value = true
    } else if (voucher.data.value?.crypto === 'ETH') {
      blockchainFee.value = convertFee(
        blockchainFees.value.ethereum.average_transaction_fee_usd_24h,
        conversion
      )
      selectedBlockchain.value = 'ETH'
      showHighFees.value = true
    } else {
      activateVoucher(reqForm)
    }
  } else {
    activateVoucher(reqForm)
  }
  loading.value = false
}

const handleAcceptHighFees = () => {
  showHighFees.value = false
  activateVoucher(activateRequestForm.value!)
}

const handleVerifyIdentity = async ({ mTanCode }: { mTanCode: string }) => {
  loading.value = true
  const { data, error } = await verify({
    nonce: nonce.value,
    walletid: activateRequestForm.value!.walletid,
    mtan: mTanCode
  })
  if (data.value) {
    nonce.value = data.value
    content.value = Content.ExchangeCrypto
  } else if (error.value) {
    await handleError(error.value.data)
  }
  loading.value = false
}

const handleResendMtanCode = async () => {
  loading.value = true
  await resetNonce()
  await activateVoucher(activateRequestForm.value!)
  errorMessage.value = ''
  loading.value = false
}

const handleExchangeCrypto = async ({ smsReceipt }: { smsReceipt: boolean }) => {
  loading.value = true
  const { data, error } = await exchange({
    nonce: nonce.value,
    walletid: activateRequestForm.value!.walletid,
    email: '',
    sendreceipt: smsReceipt
  })
  if (data.value) {
    sendReceipt.value = smsReceipt
    exchangeResponse.value = data.value
    content.value = Content.ActivationSuccess
  } else if (error.value) {
    await handleError(error.value.data)
  }
  loading.value = false
}

const handleRateUs = (rating: number) => {
  if (rating >= 4) {
    rateSuccessView.value = 1
  } else {
    rateSuccessView.value = 0
  }
  content.value = Content.GiveFeedbackSuccess
}

const handleError = async (res?: ExchangeErrorResponse) => {
  if (!res) {
    content.value = Content.GeneralError
    return
  }
  if (
    [
      ExchangeErrorCode.VOUCHER_INVALID_CODE,
      ExchangeErrorCode.VOUCHER_NOT_FOUND,
      ExchangeErrorCode.VOUCHER_USED,
      ExchangeErrorCode.VOUCHER_EXPIRED,
      ExchangeErrorCode.VOUCHER_INACTIVE,
      ExchangeErrorCode.VOUCHER_LOCKED_ADMIN,
      ExchangeErrorCode.VOUCHER_LOCKED_MTAN,
      ExchangeErrorCode.VOUCHER_LOCKED_PIN_CODE,
      ExchangeErrorCode.MTAN_INVALID_NUMBER,
      ExchangeErrorCode.MTAN_INVALID_CODE,
      ExchangeErrorCode.MTAN_EXPIRED,
      ExchangeErrorCode.NONCE_ERROR,
      ExchangeErrorCode.ACTIVATION_TIMEOUT
    ].includes(res.mKey)
  ) {
    errorMessage.value = res.message
    errorCode.value = res.mKey
  } else if (res.mKey === ExchangeErrorCode.EXCHANGE_LIMIT) {
    content.value = Content.ActivationLimitReached
  } else {
    content.value = Content.GeneralError
    errorMessage.value = res.message
  }
  if (content.value === Content.Activation) {
    await resetNonce()
  }
}

const handleClearError = () => {
  errorMessage.value = ''
  errorCode.value = undefined
}

// DEV: To Access the VA screen directly update content value in handleBackToActivation
const handleBackToActivation = () => {
  content.value = Content.Activation
  handleClearError()
  resetNonce()
}

const handleClose = () => {
  if (
    [Content.VerifyIdentity, Content.ExchangeCrypto, Content.FeesAndCommissions].includes(
      content.value
    )
  ) {
    showExit.value = true
  } else {
    store.setShowVoucherActivation(false)
  }
}

const handleExit = () => {
  showExit.value = false
  store.setShowVoucherActivation(false)
}

watch(() => route.path, handleBackToActivation)

watch(
  () => store.showVoucherActivation,
  async () => {
    if (store.showVoucherActivation) {
      history.pushState({}, '', route.fullPath)
      handleFee()
      handleBackToActivation()
      if (!cmsData.value) {
        const { data } = await useAsyncGql('voucherActivation', {
          // @ts-ignore
          locale: localeDato.value,
          options: {
            initialCache: false
          }
        })
        cmsData.value = data.value
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="postcss">
.fadeInOut-enter-active,
.fadeInOut-leave-active {
  transition: opacity 0.5s ease 0.3s;
}

.fadeInOut-leave-active {
  position: absolute;
  inset: 0;
}

.fadeInOut-enter-to {
  opacity: 1;
}

.fadeInOut-enter-from {
  opacity: 0;
}

.fadeInOut-leave-from {
  opacity: 1;
}

.fadeInOut-leave-to {
  opacity: 0;
  transition: opacity 0.5s ease;
}
</style>
