<template>
  <ModalSplitContent>
    <template #left>
      <Image :src="image" :width="642" :height="642" loading="lazy" class="object-contain" />
    </template>
    <template #right>
      <div>
        <div
          class="mb-6px hidden h-14px w-14px items-center justify-center rounded-full bg-neutral-50 md:flex"
        >
          <Icon name="check-general" class="h-7px w-7px text-green" />
        </div>
        <Image
          :src="imageSmall"
          :width="358"
          :height="232"
          loading="lazy"
          class="mb-6px md:hidden"
        />
        <div class="text-body-27-medium-px">
          {{ $t('voucherActivation.rateUsSuccess.title') }}
        </div>
        <div class="mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mt-4px">
          {{ $t('voucherActivation.rateUsSuccess.description') }}
        </div>
      </div>
      <ModalActions
        :primary-button="{
          label: $t('voucherActivation.rateUsSuccess.goToHomepage'),
          variant: 'outline',
          onClick: handleGoToHomepage
        }"
      />
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
import image from '~/assets/images/content/time-machine.png'
import imageSmall from '~/assets/images/content/time-machine-small.png'

const router = useRouter()
const localePath = useLocalePath()

const emit = defineEmits(['on-close'])

const handleGoToHomepage = () => {
  router.push(localePath('/'))
  emit('on-close')
}
</script>
