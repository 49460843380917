<template>
  <div class="flex md:h-full">
    <div
      v-if="isMd"
      class="relative flex flex-1 basis-1/2 overflow-hidden rounded-l-[20px] bg-neutral-50"
    >
      <slot name="left"></slot>
    </div>
    <div
      class="flex flex-1 basis-1/2 flex-col gap-7px pb-7px md:justify-between md:gap-0 md:px-16px md:py-10px"
    >
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import screens from '#tailwind-config/theme/screens'

const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')
</script>
