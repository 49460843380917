<template>
  <div>
    <div v-if="label" class="mb-1 text-body-16-medium-px">{{ label }}</div>
    <div ref="targetRef" class="flex gap-x-2 md:gap-x-0.5" @mouseleave="handleLeave">
      <button
        v-for="(_, idx) in maxRating"
        :key="idx"
        type="button"
        @click="handleEmit(idx)"
        @mouseenter="handleOver(idx)"
      >
        <Icon
          name="star-filled"
          class="h-8 w-8 md:h-6px md:w-6px"
          :class="$style.icon"
          :color="
            (value !== null && value >= idx + 1 && isOutside) ||
            (hoveredItemIdx !== null && Number(hoveredItemIdx) >= idx + 1)
              ? colors.black
              : colors.neutral['150']
          "
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import colors from '#tailwind-config/theme/colors'
const { pointerType } = usePointer()

export interface StarRatingProps {
  label?: string
  value?: null | 1 | 2 | 3 | 4 | 5
  maxRating?: 5
}

withDefaults(defineProps<StarRatingProps>(), {
  value: null,
  maxRating: 5
})

const emit = defineEmits(['on-update'])
const targetRef = ref<HTMLElement | null>(null)
const { isOutside } = useMouseInElement(targetRef)
const hoveredItemIdx = ref<Number | null>(null)

const handleEmit = (idx: number) => {
  emit('on-update', idx + 1)
}

const handleOver = (idx: number) => {
  if (pointerType.value !== 'mouse') {
    return
  }
  hoveredItemIdx.value = idx + 1
}

const handleLeave = () => {
  if (pointerType.value !== 'mouse') {
    return
  }
  hoveredItemIdx.value = null
}
</script>

<style lang="postcss" module>
.icon {
  use {
    transition: all 100ms ease;
    transition-delay: 100ms;
  }
}
</style>
