<template>
  <ModalSplitContent>
    <template #left>
      <div class="flex max-h-full w-full flex-col gap-8px overflow-auto p-10px">
        <div class="text-heading-18-medium-px">
          {{ $t('voucherActivation.success.discoverMore') }}
        </div>
        <TeaserFeaturedItem
          v-if="featuredTeaser"
          :data="featuredTeaser"
          @onReadMore="$emit('on-close')"
        />
        <TeaserListItem
          v-for="item in teasersList"
          :key="item.id"
          :data="item"
          clipImage
          @onReadMore="$emit('on-close')"
        />
      </div>
    </template>
    <template #right>
      <div>
        <div class="relative mb-6px w-fit">
          <Image :src="crypto?.image" :width="56" :height="56" class="rounded-full" />
          <div
            class="absolute -bottom-1px -right-1px flex h-7px w-7px items-center justify-center rounded-full bg-neutral-50"
          >
            <Icon name="check-general" class="h-3.5px w-3.5px text-green" />
          </div>
        </div>
        <div class="mb-3px text-body-27-medium-px md:mb-4px">
          {{ $t('voucherActivation.success.title') }}
        </div>
        <div
          class="text-heading-38-medium-mobile md:mb-1px md:text-heading-45-medium"
          :style="{ color: cryptoColor }"
        >
          {{ `${exchangeResponse?.exchangevalue} ${crypto?.symbol.toLocaleUpperCase()}` }}
        </div>
        <div class="mb-6px text-body-18-regular-mobile-px text-neutral-700">
          {{ $t('voucherActivation.success.executionPrice') }}
          {{ useLocaleCurrency(+exchangeResponse?.marketprice) }}
        </div>
        <hr class="h-px border-none bg-neutral-100" />
        <div class="my-6px flex flex-col gap-3px">
          <div class="flex items-center gap-3px text-body-16-regular-px">
            <Icon name="check-general" class="h-6px w-6px text-green" />
            <i18n-t keypath="voucherActivation.success.transferStarted" tag="span" scope="global">
              <template #walletId>
                <span class="text-body-16-medium-px">{{ walletId }}</span>
              </template>
            </i18n-t>
          </div>
          <div v-if="showSmsReceipt" class="flex items-center gap-3px text-body-16-regular-px">
            <Icon name="check-general" class="h-6px w-6px text-green" />
            {{ $t('voucherActivation.success.smsReceipt') }}
          </div>
        </div>
        <hr class="mb-6px h-px border-none bg-neutral-100" />
        <div class="text-body-16-regular-px text-neutral-700">
          {{ $t('voucherActivation.success.noteMessage') }}
        </div>
      </div>
      <ModalActions
        :primary-button="{
          class: 'order-2 sm:order-2',
          label: $t('voucherActivation.success.finish'),
          onClick: () => $emit('on-finish')
        }"
      >
        <template #secondary>
          <StarRating
            class="mb-1 md:mb-0"
            :label="$t('voucherActivation.success.rateUs')"
            :value="starRatingValue"
            @onUpdate="setStarRating($event)"
          />
        </template>
      </ModalActions>
      <!-- :secondary-button="{
          label: showRateUs
            ? $t('voucherActivation.success.rateUs')
            : $t('voucherActivation.success.giveFeedback'),
          onClick: () => $emit(showRateUs ? 'on-rate-us' : 'on-give-feedback')
        }"
      /> -->
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
import { ExchangeResponse } from '~/types/exchange'
import { DEFAULT_CRYPTO, ASSET_COLORS, Crypto } from '~/data/cryptoMarket'
import { useCryptoMarketStore } from '~/stores/CryptoMarketStore'
import { VoucherActivationTeaserRecord } from '~/types/generated'
import { StarRatingProps } from '~/components/feedback/StarRating.vue'

interface Props {
  walletId: string
  exchangeResponse: ExchangeResponse
  showSmsReceipt: boolean
  showRateUs?: boolean
  teasers?: Array<VoucherActivationTeaserRecord>
}

const props = defineProps<Props>()
const starRatingValue = ref<StarRatingProps['value']>(null)

const emit = defineEmits(['on-close', 'on-finish', 'on-give-feedback', 'on-rate-us'])

const setStarRating = (stars: StarRatingProps['value']) => {
  starRatingValue.value = stars
  emit('on-rate-us', stars)
}

const cryptoMarketStore = useCryptoMarketStore()

const featuredTeaser = computed(() => {
  if (props.teasers) {
    return props.teasers[0]
  }
  return null
})
const teasersList = computed(() => {
  if (props.teasers) {
    return props.teasers.slice(1)
  }
  return []
})
const crypto = computed(
  () =>
    cryptoMarketStore.getCoinsBySymbol(
      props.exchangeResponse.crypto.toLocaleLowerCase() ?? DEFAULT_CRYPTO
    )[0]
)
const cryptoColor = computed(() => ASSET_COLORS[(crypto.value?.id as Crypto) ?? DEFAULT_CRYPTO])
</script>
