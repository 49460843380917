<template>
  <ModalSplitContent>
    <template #right>
      <div>
        <div class="text-body-27-medium-px">
          {{ $t('voucherActivation.feesAndComissions.title') }}
        </div>
        <div class="mb-6px mt-3px text-body-18-regular-mobile-px text-neutral-700 md:mt-4px">
          {{ $t('voucherActivation.feesAndComissions.description') }}
        </div>
        <hr class="h-px border-none bg-neutral-100" />
        <div class="my-6px flex flex-col gap-4px">
          <div
            v-for="(item, i) in fees"
            :key="i"
            class="flex justify-between text-body-16-regular-px"
          >
            <span class="text-neutral-700">{{ item.label }}</span>
            <Spinner v-if="loading" class="h-6px w-6px" color="black" />
            <span v-else>{{ item.amount }}</span>
          </div>
        </div>
        <hr class="mb-6px h-px border-none bg-neutral-100" />
        <i18n-t
          :keypath="'voucherActivation.feesAndComissions.message'"
          tag="div"
          class="text-body-16-regular-px text-neutral-700"
          scope="global"
        >
          <template #feesLink>
            <NuxtLink
              :to="localePath('/' + feesLinkTo)"
              class="font-medium"
              target="_blank"
              noPrefetch
            >
              {{ $t('voucherActivation.feesAndComissions.feesLink') }}
            </NuxtLink>
          </template>
        </i18n-t>
      </div>
      <ModalActions
        :secondary-button="{
          label: $t('voucherActivation.goBack'),
          variant: 'outline',
          onClick: () => $emit('on-go-back')
        }"
      />
    </template>
  </ModalSplitContent>
</template>

<script setup lang="ts">
interface Props {
  walletId: string
  feesLinkTo: string
}

const props = defineProps<Props>()

defineEmits(['on-go-back'])

const { t } = useI18n()
const localePath = useLocalePath()
const { getFee } = useExchangeAPI()
const loading = ref(true)

const fees = ref([
  {
    label: t('voucherActivation.feesAndComissions.cardActivationFee'),
    amount: useLocaleCurrency(0)
  },
  {
    label: t('voucherActivation.feesAndComissions.blockchainTransferFee')
  },
  {
    label: t('voucherActivation.feesAndComissions.tradingServiceFee')
  },
  {
    label: t('voucherActivation.feesAndComissions.marketMakingFee'),
    amount: useLocaleCurrency(0)
  }
])

const { data } = await getFee({ id: props.walletId })
if (data.value) {
  fees.value[1].amount = useLocaleCurrency(data.value.fixfee)
  fees.value[2].amount = useLocalePercentage(data.value.commission * 100, { signDisplay: 'auto' })
  loading.value = false
}
</script>
