import { UseFetchOptions } from 'nuxt/app'
import { getLanguageCodeFromLocaleCode } from '~/i18n/locales'
import {
  ActivateRequest,
  ActivateResponse,
  ExchangeRequest,
  ExchangeResponse,
  GetFeeResponse,
  GetNonceResponse,
  GetPriceResponse,
  GetVoucherResponse,
  VerifyRequest
} from '~/types/exchange'
import { LocaleCode } from '~/types/locales'

export default function () {
  const config = useRuntimeConfig()
  const baseUrl = computed(() => `${config.public.exchangeUrl}`)
  const { locale } = useI18n()
  const lang = getLanguageCodeFromLocaleCode(locale.value as LocaleCode)
  const headers = { Language: lang }

  // Core compulsory sequential functions
  const getNonce = ({ opts }: { opts?: UseFetchOptions<GetNonceResponse> } = {}) => {
    return useFetch<GetNonceResponse>(`${baseUrl.value}/o`, {
      headers,
      ...opts
    })
  }

  const activate = (
    body: ActivateRequest,
    { opts }: { opts?: UseFetchOptions<GetNonceResponse> } = {}
  ) => {
    return useFetch<ActivateResponse>(`${baseUrl.value}/activate`, {
      method: 'POST',
      body,
      headers,
      ...opts
    })
  }

  const verify = (body: VerifyRequest, { opts }: { opts?: UseFetchOptions<string> } = {}) => {
    return useFetch<string>(`${baseUrl.value}/verify`, {
      method: 'POST',
      body,
      headers,
      ...opts
    })
  }

  const exchange = (
    body: ExchangeRequest,
    { opts }: { opts?: UseFetchOptions<ExchangeResponse> } = {}
  ) => {
    return useFetch<ExchangeResponse>(`${baseUrl.value}/exchange`, {
      method: 'POST',
      body,
      headers,
      ...opts
    })
  }

  // Auxiliary functions
  const getAvailable = ({ opts }: { opts?: UseFetchOptions<void> } = {}) => {
    return useFetch(`${baseUrl.value}/available`, opts)
  }

  const getVoucher = ({
    walletId,
    opts
  }: {
    walletId: string
    opts?: UseFetchOptions<GetVoucherResponse>
  }) => {
    return useFetch<GetVoucherResponse>(`${baseUrl.value}/voucher/${walletId}`, {
      headers,
      ...opts
    })
  }

  const getPrice = ({ id, opts }: { id: string; opts?: UseFetchOptions<GetPriceResponse> }) => {
    return useFetch<GetPriceResponse>(`${baseUrl.value}/price/${id}`, {
      headers,
      ...opts
    })
  }

  const getFee = ({ id, opts }: { id: string; opts?: UseFetchOptions<GetFeeResponse> }) => {
    return useFetch<GetFeeResponse>(`${baseUrl.value}/fee/${id}`, {
      headers,
      ...opts
    })
  }

  return {
    getNonce,
    activate,
    verify,
    exchange,
    getAvailable,
    getVoucher,
    getPrice,
    getFee
  }
}
