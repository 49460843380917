<template>
  <div>
    <NuxtLink
      v-if="data.image"
      :to="
        localePathByType(
          data?.reference?.__typename as string,
          data?.reference?.slug as string,
          data.reference?.__typename === 'ArticleRecord' ||
            data.reference?.__typename === 'HelpArticleRecord'
            ? (data.reference.category?.slug as string)
            : undefined,
          data.reference?.__typename === 'HelpArticleRecord'
            ? (data.reference.topic?.slug as string)
            : undefined
        )
      "
      class="block pb-4px"
    >
      <Picture
        class="aspect-3/2 h-[240px] w-full"
        clipType="rounded"
        clipDirection="top"
        :data="data.image"
        pictureClass="!rounded-t-4xl h-full w-full"
        imgClass="object-cover w-full h-full"
        loading="lazy"
      />
    </NuxtLink>
    <div class="mb-2px text-heading-18-medium-px">{{ data?.title }}</div>
    <NuxtLink
      :to="
        localePathByType(
          data?.reference?.__typename as string,
          data?.reference?.slug as string,
          data.reference?.__typename === 'ArticleRecord' ||
            data.reference?.__typename === 'HelpArticleRecord'
            ? (data.reference.category?.slug as string)
            : undefined,
          data.reference?.__typename === 'HelpArticleRecord'
            ? (data.reference.topic?.slug as string)
            : undefined
        )
      "
      class="text-body-16-regular-px text-neutral-900"
      @click="$emit('on-read-more')"
    >
      {{ data.referenceLabel }}
      <Icon class="h-3px w-3px" name="chevron-right-general" />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { VoucherActivationTeaserRecord } from '~/types/generated'

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object as PropType<VoucherActivationTeaserRecord>,
    default: () => {}
  }
})

defineEmits(['on-read-more'])
</script>
